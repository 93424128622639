@import "./variables.scss";
@import "./reset.scss";

* {
  box-sizing: border-box;
}

html,
body,
body > div:first-child,
#root,
#root > div {
  height: 100%;
  top: 0;
}

html {
  font-size: calc(14px + (16 - 14) * (100vw - 800px) / (1400 - 800) * 0.618) !important;
  width: 100%;
}

.root {
  width: 100%;
  overflow: auto;
}

body {
  font-family: Roboto, sans-serif;
  //background: $background;
  color: $primary-font-color;
}

th,
td {
  vertical-align: middle;
}